import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AUTH_REFRESH_PATH } from './contants';
import { getAccessToken, getRefreshToken, getRememberMeState } from 'entities/User';
import { API_URL } from 'app/config/envVariables';
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    const token = getAccessToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);

      return headers;
    }
  },
});

const refreshQuery = fetchBaseQuery({
  baseUrl: API_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    const refresh = getRefreshToken();

    if (refresh) {
      headers.set('authorization', `Bearer ${refresh}`);

      return headers;
    }
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const refreshResult = await refreshQuery(AUTH_REFRESH_PATH, api, extraOptions);
    if (refreshResult?.data) {
      const { accessToken, refreshToken } = refreshResult.data as Record<'refreshToken' | 'accessToken', string>;

      api.dispatch({
        type: 'user/setCredentials',
        payload: {
          credentials: {
            accessToken,
            refreshToken,
          },
          remember: getRememberMeState(),
        },
      });

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch({ type: 'user/logOut' });
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Cities',
    'Reservations',
    'Available boxes',
    'Invoices',
    'Boxes',
    'Leads',
    'Contracts',
    'Available sizeCodes',
    'Contract details',
    'Warehouses for booking',
    'My bookings',
    'Notes',
    'Late charge settings',
    'Users',
  ],
  endpoints: () => ({}),
});
