import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useGetPaginatedUsersListingQuery, UserForListing, UserRoles } from 'entities/User';
import { useNavigate } from 'react-router-dom';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as NoteTableIcon } from 'shared/assets/icons/NoteTableIcon.svg';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ReactComponent as EditUserTableIcon } from 'shared/assets/icons/EditUserTableIcon.svg';
import { ReactComponent as CustomersIcon } from 'shared/assets/icons/CustomersIcon.svg';
import { ReactComponent as ManagerIcon } from 'shared/assets/icons/ManagerIcon.svg';
import { ReactComponent as SuperUserIcon } from 'shared/assets/icons/SuperUserIcon.svg';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { ReactComponent as LeadIcon } from 'shared/assets/icons/LeadIcon.svg';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { Paragraph } from 'shared/ui/Paragraph';
import { ExportModal, useOpenExportModal, getCustomerColumnKeys, ExportEntity } from 'features/ExportModal';
import { useGetCurrencySymbol } from 'app/appState';
import { TableFilterContextProvider, useTableFilterContext } from 'features/TableFilter';
import { SendOfferModal } from 'widgets/SendOfferModal';
import { LeadsTable } from './LeadsTable';

export const UsersPage: FC = memo(() => {
  const [selectedTabKey, setSelectedTabKey] = useState('Customers');

  const { openExportModal } = useOpenExportModal();

  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();

  const { appliedFilters } = useTableFilterContext();

  const { t } = useAppTranslation(['user', 'common']);

  const goToUserContracts = useCallback(
    (user: UserForListing): void => {
      navigate(`${AppRoutes.USERS}/${user.userId}/contracts`);
    },
    [navigate],
  );

  const goToUserProfile = useCallback(
    (user: UserForListing): void => {
      navigate(`${AppRoutes.PROFILE}/${user.userId}`);
    },
    [navigate],
  );

  const rowActions: Array<ServerTableRowActions<UserForListing>> = useMemo(
    () => [
      {
        name: 'addNote',
        icon: <NoteTableIcon />,
        theme: 'clear',
        description: t('Add note'),
        onClick: (selectedUser) => {
          console.log(selectedUser);
        },
      },
      {
        name: 'viewContracts',
        icon: <ContractsTableIcon />,
        theme: 'clear',
        description: t('View contracts'),
        onClick: goToUserContracts,
      },
      {
        name: 'editUser',
        icon: <EditUserTableIcon />,
        theme: 'clear',
        description: t('Edit user'),
        onClick: goToUserProfile,
      },
    ],
    [goToUserContracts, goToUserProfile, t],
  );

  const columns = useMemo<ColumnsType<UserForListing>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => (
          <div className="text-primary font-semibold">
            {record.firstName} {record.lastName}
          </div>
        ),
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', render: (_, record) => record.phone },
      { title: t('Date'), key: 'createdAt', render: (_, record) => getClientDateFormat(record.createdAt) },
      {
        title: t('Balance'),
        key: 'balance',
        render: (_, record) => (
          <div>
            {record.email && (
              <div className="text-success font-semibold">{record.balance ? `${record.balance} ${currencySymbol}` : ''}</div>
            )}
          </div>
        ),
      },
      {
        title: t('Debt'),
        key: 'contractDebt',
        render: (_, record) => (
          <div>
            {record.email && (
              <div className="text-error font-semibold">{record.contractDebt ? `${record.contractDebt} ${currencySymbol}` : ''}</div>
            )}
          </div>
        ),
      },
      {
        title: t('Note'),
        key: 'note',
        width: '20%',
        render: (_, record) => (
          <Paragraph rows={2} className="text-primaryLight font-normal">
            {record.note}
          </Paragraph>
        ),
      },
    ],
    [currencySymbol, t],
  );

  const tabs = useMemo(
    () => [
      { key: 'Customers', label: t('Customers'), icon: <CustomersIcon /> },
      { key: 'Managers', label: t('Managers'), icon: <ManagerIcon /> },
      { key: 'Superusers', label: t('Superusers'), icon: <SuperUserIcon /> },
      { key: 'Leads', label: t('Leads'), icon: <LeadIcon /> },
    ],
    [t],
  );

  const customerTableActions: Array<ServerTableActions<UserForListing>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: appliedFilters,
            columns: getCustomerColumnKeys(t),
            entity: ExportEntity.USER,
          });
        },
      },
    ],
    [appliedFilters, openExportModal, t],
  );

  const renderTabContent = useCallback((): Nullable<ReactNode> => {
    switch (selectedTabKey) {
      case 'Customers':
        return (
          <ServerTable
            columns={columns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.CUSTOMER, orderBy: 'createdAt:DESC' }}
            rowKey="userId"
            search
            tableActions={customerTableActions}
            tableActionsPosition="prev"
          />
        );
      case 'Managers':
        return (
          <ServerTable
            columns={columns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.MANAGER }}
            rowKey="userId"
            search
          />
        );
      case 'Superusers':
        return (
          <ServerTable
            columns={columns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.SUPERUSER }}
            rowKey="userId"
            search
          />
        );
      case 'Leads':
        return (
          <TableFilterContextProvider>
            <LeadsTable />
          </TableFilterContextProvider>
        );
      default:
        return null;
    }
  }, [selectedTabKey, columns, rowActions, customerTableActions]);

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Users')}</div>
      <TabSwitcher tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        {renderTabContent()}
      </TabSwitcher>
      <ExportModal />
      <SendOfferModal />
    </div>
  );
});
