import { Column } from 'features/ExportModal/model/types';
import { TFunction } from 'i18next';

export const getLeadColumnKeys = (t: TFunction): Column[] => {
  return [
    {
      key: 'name',
      title: t('Name'),
    },
    {
      key: 'email',
      title: t('Email'),
    },
    {
      key: 'phone',
      title: t('Phone'),
    },
    {
      key: 'warehouseId',
      title: t('Warehouse'),
    },
    {
      key: 'createdAt',
      title: t('Date'),
    },
    {
      key: 'square',
      title: t('Box size'),
    },
    {
      key: 'isLeft',
      title: t('Left'),
    },
    {
      key: 'note',
      title: t('Note'),
    },
    {
      key: 'contractId',
      title: t('Active contract'),
    },
    {
      key: 'isContractPaid',
      title: t('Active contract but not paid'),
    },
    {
      key: 'contractEndDate',
      title: t('Ended contract'),
    },
  ];
};

export const getCustomerColumnKeys = (t: TFunction): Column[] => {
  return [
    {
      key: 'name',
      title: t('Name'),
    },
    {
      key: 'email',
      title: t('Email'),
    },
    {
      key: 'phone',
      title: t('Phone'),
    },
    {
      key: 'createdAt',
      title: t('Date'),
    },
    {
      key: 'contractDebt',
      title: t('Debt'),
    },
    {
      key: 'note',
      title: t('Note'),
    },
  ];
};

export const getContractColumnKeys = (t: TFunction): Column[] => {
  return [
    {
      key: 'warehouse',
      title: t('Warehouse'),
    },
    {
      key: 'contractNumber',
      title: t('Contract number'),
    },
    {
      key: 'boxItem',
      title: t('Box'),
    },
    {
      key: 'boxItemSquare',
      title: t('Square'),
    },
    {
      key: 'customerEmail',
      title: t('Customer'),
    },
    {
      key: 'createdAt',
      title: t('Date of creation'),
    },
    {
      key: 'startDate',
      title: t('Arrival Date'),
    },
    {
      key: 'noticeLeaveDate',
      title: t('Planned Departure Date'),
    },
    {
      key: 'leaveDate',
      title: t('Closing Date'),
    },
  ];
};

export const getInvoiceColumnKeys = (t: TFunction): Column[] => {
  return [
    {
      key: 'warehouse',
      title: t('Warehouse'),
    },
    {
      key: 'documentNumber',
      title: t('Invoice number'),
    },
    {
      key: 'unitType',
      title: t('Unit type'),
    },
    {
      key: 'isPaid',
      title: t('Paid'),
    },
    {
      key: 'creditNote',
      title: t('Credit Note'),
    },
    {
      key: 'customer',
      title: t('Customer'),
    },
    {
      key: 'amount',
      title: t('Amount'),
    },
    {
      key: 'createdAt',
      title: t('Created date'),
    },
    {
      key: 'dueDate',
      title: t('Due date'),
    },
    {
      key: 'debt',
      title: t('Debt'),
    },
    {
      key: 'dateFrom',
      title: t('Date from'),
    },
    {
      key: 'dateTo',
      title: t('Date to'),
    },
  ];
};

export const getBoxColumnKeys = (t: TFunction): Column[] => {
  return [
    {
      key: 'name',
      title: t('Box'),
    },
    {
      key: 'contractNumber',
      title: t('Contract'),
    },
    {
      key: 'reservationNumber',
      title: t('Reservation'),
    },
    {
      key: 'rates',
      title: t('Cost'),
    },
    {
      key: 'square',
      title: t('Square'),
    },
    {
      key: 'warehouseName',
      title: t('Warehouse'),
    },
    {
      key: 'lockAddress',
      title: t('Lock number'),
    },
    {
      key: 'lockStatus',
      title: t('Lock status'),
    },
    {
      key: 'lastOpened',
      title: t('Last opened'),
    },
    {
      key: 'userEmail',
      title: t('Customer'),
    },
    {
      key: 'isActive',
      title: t('Availability'),
    },
    {
      key: 'isForOwnNeeds',
      title: t('For own needs'),
    },
    {
      key: 'floorName',
      title: t('Floor'),
    },
    {
      key: 'note',
      title: t('Note'),
    },
  ];
};
