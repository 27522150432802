import { Collapse, Divider } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, useCallback } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { User, useAdminRole } from 'entities/User';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { Payment, PaymentStatus, generatePaymentPdfLink } from 'entities/Payment';
import { MarkAsRefundedPayment } from 'features/MarkAsRefundedPayment';
import { CreditAndRefundPayment } from 'features/CreditAndRefundPayment';
import { CreditPayment } from 'features/CreditPayment';
import s from './PaymentCard.module.scss';
import { Deposit } from 'entities/Deposit';

interface PaymentCardProps {
  user: User;
  payment: Payment;
  deposit?: Deposit;
  isClosedContract: boolean;
  isDepositPayment?: boolean;
}

export const PaymentCard: FC<PaymentCardProps> = memo((props) => {
  const {
    payment,
    user: { firstName, lastName },
    isClosedContract,
  } = props;

  const { documentNumber: invoiceNumber, invoiceEmail } = payment.invoice;

  const { t } = useAppTranslation(['contracts', 'common']);

  const isAdmin = useAdminRole();

  const currencySymbol = useGetCurrencySymbol();

  const isPaymentCompleted = payment.paymentStatus === PaymentStatus.COMPLETED;

  const creditedAmount = payment.creditNote?.creditedAmount;
  const refundedAmount = payment.refund?.amount;

  const PaymentContent = useCallback((): JSX.Element => {
    return (
      <div>
        <div>
          {t('From')}: {firstName} {lastName}
        </div>
        <div>
          {t('Mail')}: {invoiceEmail}
        </div>
        <Divider className="border-primary" />

        <BulletsTable
          theme="clear"
          rows={[
            {
              label: t('Payment method'),
              value: t(payment.paymentMethod, { ns: 'common' }),
            },
            {
              label: t('Payment'),
              value: `${roundNumber(payment.amount)} ${currencySymbol}`,
              emptyValueSymbol: `0 ${currencySymbol}`,
            },
            {
              label: t('Description'),
              value: isAdmin ? payment.description : null,
              emptyValueSymbol: '-',
            },
          ]}
        />

        {payment.creditNote && (
          <>
            <Divider className="border-primary" />
            <BulletsTable
              theme="clear"
              rows={[
                {
                  label: t('Credited amount'),
                  value: `${roundNumber(payment.creditNote.creditedAmount)} ${currencySymbol}`,
                  emptyValueSymbol: `0 ${currencySymbol}`,
                },
                {
                  label: t('Description'),
                  value: isAdmin ? payment.creditNote?.description : null,
                  emptyValueSymbol: '-',
                },
              ]}
            />
          </>
        )}

        {payment.refund && (
          <>
            <Divider className="border-primary" />
            <BulletsTable
              theme="clear"
              rows={[
                {
                  label: t('Refund amount'),
                  value: `${roundNumber(payment.refund.amount)} ${currencySymbol}`,
                  emptyValueSymbol: `0 ${currencySymbol}`,
                },
                {
                  label: t('Description'),
                  value: isAdmin ? payment.refund?.description : null,
                  emptyValueSymbol: '-',
                },
              ]}
            />
          </>
        )}
        <div className="my-3">
          <a
            href={generatePaymentPdfLink(payment.paymentId)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-accent"
          >
            {t(!payment.creditNote ? 'Download PDF' : 'Download Credit Note PDF')}
          </a>
        </div>

        {isAdmin && !payment.refund && (
          <div className="flex flex-col gap-3 mt-6">
            {payment.creditNote ? (
              <>
                <MarkAsRefundedPayment payment={payment} action={<Button theme="secondary">{t('Mark as Refunded')}</Button>} />
                <CreditAndRefundPayment payment={payment} action={<Button theme="danger">{t('Refund')}</Button>} />
              </>
            ) : (
              <div className="flex flex-col gap-3">
                <CreditPayment
                  paymentId={payment.paymentId}
                  amount={payment.amount}
                  action={<Button theme="primary">{t('Credit Payment')}</Button>}
                />
                <CreditAndRefundPayment payment={payment} action={<Button theme="danger">{t('Credit and Refund')}</Button>} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }, [currencySymbol, firstName, invoiceEmail, isAdmin, isClosedContract, lastName, payment, t]);

  return (
    <Collapse
      className={classNames(s.collapse, {
        [s.credited]: creditedAmount && creditedAmount === payment.amount,
        [s.refunded]: refundedAmount && refundedAmount === payment.amount,
        [s.paymentAndCreditNote]: creditedAmount && !refundedAmount && creditedAmount !== payment.amount,
        [s.paymentAndRefund]: refundedAmount && refundedAmount !== payment.amount,
        [s.completed]: isPaymentCompleted && !payment.refund && !payment.creditNote,
      })}
      items={[
        {
          key: payment.paymentId,
          label: (
            <>
              <div className="flex justify-between font-semibold text-lg">
                <div>
                  <span className="font-semibold text-lg">
                    {t('Payment')} {payment.documentNumber}{' '}
                  </span>
                  {payment.paymentStatus !== PaymentStatus.COMPLETED && <span className="text-m">({t(payment.paymentStatus)})</span>}
                </div>
                <div className="text-accent">
                  {t('Invoice')} {invoiceNumber}
                </div>
              </div>
              <div>{getClientDateFormat(payment.createdAt)}</div>
            </>
          ),
          children: <PaymentContent />,
        },
      ]}
      expandIcon={(panelProps) => (
        <CollapseArrow
          className={classNames('ease-linear duration-200', {
            '-rotate-90 fill-primaryLight': panelProps.isActive,
            'rotate-90 fill-secondaryAccent': !panelProps.isActive,
          })}
        />
      )}
      expandIconPosition="end"
    />
  );
});
