import { Form } from 'antd';
import { ModalMode } from 'app/types/common';
import { City, CountryType, useCreateCityMutation, useUpdateCityMutation } from 'entities/City';

import React, { FC, memo, useCallback, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { InputNumber } from 'shared/ui/InputNumber';
import { Select } from 'shared/ui/Select';
import { LATITUDE_REGEX, LONGITUDE_REGEX } from 'shared/utils/regex';
import { showNotification } from 'app/providers/NotificationsProvider';

interface CityFormProps {
  city: Nullable<City>;
  mode: Nullable<ModalMode>;
  handleClose: () => void;
}

export const CityForm: FC<CityFormProps> = memo((props) => {
  const { city, mode, handleClose } = props;

  const { t } = useAppTranslation(['city', 'common']);

  const [creatyCity, { isLoading: isCreationLoading }] = useCreateCityMutation();
  const [updateCity, { isLoading: isUpdationLoading }] = useUpdateCityMutation();

  const isLoading = isUpdationLoading || isCreationLoading;

  const [form] = Form.useForm();

  const countryOptions = Object.values(CountryType).map((country) => ({
    label: t(country, { ns: 'common' }),
    value: country,
  }));

  useEffect(() => {
    mode === ModalMode.EDIT ? form.setFieldsValue(city) : form.resetFields();
  }, [mode, form, city]);

  const handleSubmit = useCallback(
    async (data: City): Promise<void> => {
      try {
        switch (mode) {
          case ModalMode.CREATE:
            await creatyCity(data).unwrap();
            showNotification('info', t('Success'), t('City has been successfully created'));
            break;
          case ModalMode.EDIT:
            if (city) {
              const { cityId } = city;
              await updateCity({ ...data, cityId }).unwrap();
              showNotification('info', t('Success'), t('City has been successfully updated'));
            }
            break;
        }

        form.resetFields();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t(`Error when ${mode === ModalMode.CREATE ? 'creating' : 'updating'} city`));
      }

      handleClose();
    },
    [handleClose, mode, form, creatyCity, city, updateCity, t],
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col">
      <div className="text-2xl mb-6">{t(`${mode} city`)}</div>
      <Form.Item label={t('Select country')} name="country" rules={[{ required: true, message: `${t('Please, select country')}!` }]}>
        <Select options={countryOptions} placeholder={t('Please, select country')} bordered />
      </Form.Item>
      <Form.Item label={t('Enter city name')} name="name" rules={[{ required: true, message: `${t('Please, enter city name')}!` }]}>
        <Input placeholder={t('Please, enter city name')} bordered />
      </Form.Item>
      <Form.Item
        label={t('Latitude')}
        name="latitude"
        rules={[
          { required: true, message: `${t('Please, enter city latitude')}!` },
          { pattern: LATITUDE_REGEX, message: `${t('Invalid latitude format')}!` },
        ]}
      >
        <InputNumber placeholder={t('Please, enter latitude')} bordered />
      </Form.Item>

      <Form.Item
        label={t('Longitude')}
        name="longitude"
        rules={[
          { required: true, message: `${t('Please, enter city longitude')}!` },
          { pattern: LONGITUDE_REGEX, message: `${t('Invalid longitude format')}!` },
        ]}
      >
        <InputNumber placeholder={t('Please, enter longitude')} bordered />
      </Form.Item>

      <div className="flex justify-end gap-3 mt-2">
        <Button theme="secondary" onClick={handleClose}>
          {t('Cancel', { ns: 'common' })}
        </Button>

        <Button type="submit" isLoading={isLoading}>
          {t('Save', { ns: 'common' })}
        </Button>
      </div>
    </Form>
  );
});
