import React, { FC, memo, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useOnLockStatusChanged } from 'entities/Box';
import { showNotification } from 'app/providers/NotificationsProvider';
import { TableFilterContextProvider } from 'features/TableFilter';
import { BoxesTable } from './BoxesTable';

export const BoxesPage: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'boxes', 'common']);

  const newLockStatus = useOnLockStatusChanged();

  useEffect(() => {
    if (newLockStatus) {
      showNotification(
        'warning',
        t('Lock status change'),
        t('Lock {{lockId}} has change status to {{status}}', { lockId: newLockStatus.lockId, status: newLockStatus.status }),
      );
    }
  }, [newLockStatus, t]);

  return (
    <>
      <TableFilterContextProvider>
        <BoxesTable />
      </TableFilterContextProvider>
    </>
  );
});
