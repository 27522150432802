import classNames from 'classnames';
import React, { FC, memo, ReactNode, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Modal } from 'shared/ui/Modal';
import { StepData, Stepper } from 'features/Stepper';
import { getActiveStepIndex } from '../model/selectors/getActiveStepIndex';
import { stepperModalActions } from '../model/slice/stepperModalSlice';
import { ReactComponent as SelectArrow } from 'shared/assets/icons/SelectArrow.svg';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';

interface StepperModalProps {
  isOpened: boolean;
  steps: StepData[];
  stepsContent: ReactNode[];
  onClose: () => void;
  width?: number;
}

export const StepperModal: FC<StepperModalProps> = memo((props) => {
  const { steps, stepsContent, onClose, isOpened, width } = props;

  const dispatch = useAppDispatch();

  const activeStepIndex = useAppSelector(getActiveStepIndex);

  const activeStepLabel = steps[activeStepIndex].label;

  const hasPreviousStep = activeStepIndex > 0;

  const changeStep = useCallback(
    (stepName: string) => {
      const newStepIndex = steps.findIndex((step) => step.label === stepName);

      dispatch(stepperModalActions.changeActiveStepIndex(newStepIndex));
    },
    [dispatch, steps],
  );

  const goToPreviousStep = useCallback(() => {
    dispatch(stepperModalActions.goToPreviousStep());
  }, [dispatch]);

  const renderStepContent = (): ReactNode => {
    return stepsContent[activeStepIndex];
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose} closeIcon={false} width={width}>
      <div className="flex items-center">
        <div
          className={classNames('w-[44px] h-[44px] rounded-full bg-secondary flex justify-center items-center cursor-pointer mr-6', {
            'opacity-30': !hasPreviousStep,
          })}
          onClick={hasPreviousStep ? goToPreviousStep : undefined}
        >
          <SelectArrow className="[&>path]:stroke-primary rotate-90" />
        </div>
        <Stepper steps={steps} activeStep={activeStepLabel} onChangeStep={changeStep} />
        <div className="w-[44px] h-[44px] rounded-full bg-secondary flex justify-center items-center cursor-pointer mr-6" onClick={onClose}>
          <CloseIcon className="[&>path]:stroke-primary" width={32} height={32} />
        </div>
      </div>
      <div className="mt-8">{renderStepContent()}</div>
    </Modal>
  );
});
