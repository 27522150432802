import React, { createContext, FC, memo, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { FilterTags, SetFilterArgs, TableFilterContextSchema, TableFilterType } from '../types';

export const TableFilterContext = createContext<TableFilterContextSchema>({
  isOpened: false,
  filters: {},
  appliedFilters: {},
  tags: {},
  setFiltersOpened: () => undefined,
  changeFilters: () => undefined,
  clearCurrentFilters: () => undefined,
  clearAllFilters: () => undefined,
  applyFilters: () => undefined,
  applyQueryFilters: () => undefined,
});

export const TableFilterContextProvider: FC<PropsWithChildren> = memo((props) => {
  const [isOpened, setOpened] = useState(false);
  const [filters, setFilters] = useState<TableFilterType>({});
  const [appliedFilters, setAppliedFilters] = useState<TableFilterType>({});

  const { deleteParam, clearQueryString } = useQueryString();

  const setFiltersOpened = useCallback((payload: boolean): void => {
    setOpened(payload);
  }, []);

  const deleteAppliedFilter = useCallback(
    (payload: string): void => {
      deleteParam(payload);

      setFilters((prevState) => {
        const currentFilters = { ...prevState };
        delete currentFilters[payload];

        return currentFilters;
      });

      setAppliedFilters((prevState) => {
        const currentFilters = { ...prevState };
        delete currentFilters[payload];

        return currentFilters;
      });
    },
    [deleteParam],
  );

  const changeFilters = useCallback(
    (payload: SetFilterArgs): void => {
      const { key, value, label, title, filterGroup } = payload;

      setFilters((prevState) => {
        const updatedFilters: TableFilterType = {
          ...prevState,
          [key]: {
            label,
            value,
            title,
            onDelete: () => {
              deleteAppliedFilter(key);
            },
          },
        };

        if (filterGroup) {
          updatedFilters[key] = { ...updatedFilters[key], filterGroup };
        }

        return updatedFilters;
      });
    },
    [deleteAppliedFilter],
  );

  const clearCurrentFilters = useCallback((payload: string): void => {
    setFilters((prevState) => {
      const currentFilters = { ...prevState };
      delete currentFilters[payload];

      return currentFilters;
    });
  }, []);

  const clearAllFilters = useCallback((): void => {
    setFilters({});
    setAppliedFilters({});
    clearQueryString();
  }, [clearQueryString]);

  const applyFilters = useCallback((): void => {
    setAppliedFilters(filters);
  }, [filters]);

  const applyQueryFilters = useCallback(
    (payload: SetFilterArgs): void => {
      const { key, value, label, title, filterGroup } = payload;

      setFilters((prevState) => {
        const updatedFilters: TableFilterType = {
          ...prevState,
          [key]: {
            label,
            value,
            title,
            onDelete: () => {
              deleteAppliedFilter(key);
            },
          },
        };

        if (filterGroup) {
          updatedFilters[key] = { ...updatedFilters[key], filterGroup };
        }

        setAppliedFilters(updatedFilters);

        return updatedFilters;
      });
    },
    [deleteAppliedFilter],
  );

  const tags = useMemo((): FilterTags => {
    const tags: FilterTags = {};

    for (const key in appliedFilters) {
      const { value, label, title, onDelete } = appliedFilters[key];

      tags[key] = { value, label, title, onDelete };
    }

    return tags;
  }, [appliedFilters]);

  return (
    <TableFilterContext.Provider
      value={{
        isOpened,
        filters,
        appliedFilters,
        tags,
        setFiltersOpened,
        changeFilters,
        clearCurrentFilters,
        clearAllFilters,
        applyFilters,
        applyQueryFilters,
      }}
    >
      {props.children}
    </TableFilterContext.Provider>
  );
});
