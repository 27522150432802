import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import React, { FC, memo, useMemo } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { UnitType } from 'entities/Contract';
import { Invoice } from 'entities/Invoice';
import { Modal } from 'shared/ui/Modal';
import { ServerTable } from 'shared/ui/ServerTable';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useGetUserInvoicesQuery } from 'entities/User';

interface InvoicesModalProps {
  isOpened: boolean;
  userId: string | undefined;
  onCLose: () => void;
}

export const InvoicesModal: FC<InvoicesModalProps> = memo((props) => {
  const { isOpened, userId, onCLose } = props;

  const { t } = useAppTranslation('profile');

  const currencySymbol = useGetCurrencySymbol();

  const columns = useMemo<ColumnsType<Invoice>>(
    () => [
      {
        title: t('Customer'),
        width: '20%',
        key: 'customer',
        render: (_, record) => <div className="text-primary font-normal">{`${record.user.firstName} ${record.user.lastName}`}</div>,
      },
      {
        title: t('Invoice'),
        key: 'invoice',
        width: '15%',
        render: (_, record) => (
          <div className="text-primary font-normal">
            {t('Invoice')} #{record.documentNumber}
          </div>
        ),
      },
      {
        title: t('Unit type'),
        width: '15%',
        key: 'unitType',
        render: (_, record) => {
          const boxInvoiceItem = record.invoiceItems.find((invoiceItem) => invoiceItem.contractItem?.box);
          const box = boxInvoiceItem?.contractItem?.box;

          const depositInvoiceItem = record.invoiceItems.find(
            (invoiceItem) => invoiceItem.contractItem?.contractUnitType === UnitType.DEPOSIT,
          );

          if (box) {
            return <div className="text-primary font-normal">{box.name}</div>;
          }

          if (!box && depositInvoiceItem) {
            return <div className="text-primary font-normal">{depositInvoiceItem.contractItem?.description}</div>;
          }

          if (!record.contract?.contractId) {
            return <div className="text-primary font-normal">{t('Personal Invoice', { ns: 'common' })}</div>;
          }

          return <div className="text-primary font-normal">{t('Other', { ns: 'common' })}</div>;
        },
      },

      {
        title: t('Contract number'),
        width: '10%',
        key: 'contract',
        render: (_, record) => <div className="text-primary font-normal">#{record.contract?.contractNumber}</div>,
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '20%',
        render: (_, record) => <div className="text-primary font-normal">{record.warehouse?.name}</div>,
      },
      {
        title: t('Total amount'),
        key: 'totalAmount',
        width: '20%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.amount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Due date'),
        key: 'dueDate',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.dueDate)}</div>,
      },
      {
        title: t('Created date'),
        key: 'createdDate',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
    ],
    [t, currencySymbol],
  );

  const highlightRow = (record: Invoice): string => {
    return classNames({
      'bg-successLight': record.debt === 0 && !record.creditNotes.length,
      'bg-warnLight': record.debt === 0 && record.creditNotes.length,
      'bg-gradient-to-r from-successLight to-warnLight': record.debt === 0 && record.creditNotes.length && record.payments.length,
      'bg-gradient-to-r from-successLight via-warnLight to-light': record.debt !== 0 && record.creditNotes.length && record.payments.length,
    });
  };

  return (
    <Modal width={1200} isOpen={isOpened} onClose={onCLose}>
      <ServerTable
        columns={columns}
        fetchData={useGetUserInvoicesQuery}
        rowKey="invoiceId"
        rowClassName={highlightRow}
        defaultFilters={{ userId }}
      />
    </Modal>
  );
});
