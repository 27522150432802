import { Form } from 'antd';
import React, { FC, memo, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { UpdateUser } from 'features/UpdateUser';
import { AddressFormFields } from '../../model/types';

interface AddressFormProps {
  userId: string;
  country: string;
  city: string;
  street: string;
  zip: string;
  house: string;
  apartment: string;
}

export const AddressForm: FC<AddressFormProps> = memo((props) => {
  const { userId, zip, city, apartment, country, house, street } = props;

  const { t } = useAppTranslation('profile');

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      zip,
      city,
      apartment,
      country,
      house,
      street,
    });
  }, [apartment, city, country, form, house, street, zip]);

  return (
    <div className="mt-10">
      <div className="mb-7 font-semibold text-2xl">{t('Address')}</div>
      <Form className="space-y-2" name="addressForm" form={form} layout="vertical">
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="country"
            label={t('Country')}
            rules={[{ required: true, message: t('Please enter your country!') }]}
          >
            <Input bordered placeholder={t('Enter country')} />
          </Form.Item>
          <Form.Item className="w-full" name="city" label={t('City')} rules={[{ required: true, message: t('Please enter your city!') }]}>
            <Input bordered placeholder={t('Enter city')} />
          </Form.Item>
        </div>
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="street"
            label={t('Street')}
            rules={[{ required: true, message: t('Please enter your street!') }]}
          >
            <Input bordered placeholder={t('Enter street')} />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="zip"
            label={t('Zip code')}
            rules={[{ required: true, message: t('Please enter your zip code!') }]}
          >
            <Input bordered placeholder={t('Enter zip code')} />
          </Form.Item>
        </div>
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="house"
            label={t('House')}
            rules={[{ required: true, message: t('Please enter your house!') }]}
          >
            <Input bordered placeholder={t('Enter house')} />
          </Form.Item>
          <Form.Item className="w-full" name="apartment" label={t('Apartment')}>
            <Input bordered placeholder={t('Enter apartment')} />
          </Form.Item>
        </div>
        <div className="flex justify-end ml-7">
          <UpdateUser
            userId={userId}
            formInstance={form}
            action={
              <Button containerClassName="basis-1/2" size="large">
                {t('Change address')}
              </Button>
            }
          />
        </div>
      </Form>
    </div>
  );
});
