import { Form, Input as AntdInput } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useUpdateUserMutation } from 'entities/User/api/updateUserApi';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';

interface NewPasswordModalProps {
  userId: string;
  isOpened: boolean;
  onClose: () => void;
}

export const NewPasswordModal: FC<NewPasswordModalProps> = memo((props) => {
  const { userId, isOpened, onClose } = props;

  const { t } = useAppTranslation(['profile', 'common']);

  const [form] = Form.useForm();

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const resetPassword = useCallback(
    async ({ password }: { password: string }) => {
      if (userId) {
        try {
          await updateUser({ userId, params: { password } }).unwrap();
          showNotification('info', t('Success', { ns: 'common' }), t('Password reset has been successful', { ns: 'common' }));
        } catch (error: CustomAny) {
          console.log(error);
          showNotification('error', t('Error', { ns: 'common' }), t('Error when resetting password', { ns: 'common' }));
        }
      }
    },
    [userId, updateUser, t],
  );

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Form className="pt-10" form={form} layout="vertical" onFinish={resetPassword}>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('Please enter your new password!', { ns: 'common' }) },
            { min: 8, message: t('Password must be at least 8 characters!', { ns: 'common' }) },
          ]}
        >
          <AntdInput.Password className="h-14" placeholder={t('New password')} size="large" />
        </Form.Item>
        <Form.Item
          name="repeatPassword"
          rules={[
            { required: true, message: t('Please repeat password!', { ns: 'common' }) },
            ({ getFieldValue }) => ({
              async validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  await Promise.resolve();
                  return;
                }
                return await Promise.reject(new Error(t('Password must be the same', { ns: 'common' })));
              },
            }),
          ]}
        >
          <AntdInput.Password className="h-14" placeholder={t('Repeat password')} size="large" />
        </Form.Item>
        <Button containerClassName="mt-12" size="large" type="submit" isLoading={isLoading}>
          {t('Set new password')}
        </Button>
      </Form>
    </Modal>
  );
});
