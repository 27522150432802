import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getContractOptions } from 'entities/Contract';
import { getLoggedUserData } from '../../model/selectors/getLoggedUserData';
import { UserAccountType } from '../../model/types';

export const useCheckUserDataComplete = (): boolean => {
  const loggedUserData = useAppSelector(getLoggedUserData);
  const contractOptions = useAppSelector(getContractOptions);

  if (!loggedUserData) {
    return false;
  }

  const {
    userId,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    street,
    house,
    zip,
    email,
    country,
    city,
    language,
    accountType,
    role,
    company,
  } = loggedUserData;

  let hasEmptyFields =
    !userId ||
    !firstName ||
    !lastName ||
    !phone ||
    !dateOfBirth ||
    !street ||
    !house ||
    !zip ||
    !email ||
    !country ||
    !city ||
    !language ||
    !accountType ||
    !role;

  if (contractOptions.accountType === UserAccountType.BUSINESS) {
    hasEmptyFields = hasEmptyFields || !company;
  }

  return !hasEmptyFields;
};
