import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { Contract, useGetContractsByUserIdQuery } from 'entities/Contract';
import { Table } from 'shared/ui/Table';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';

export const RentalHistoryContractsTable: FC = memo(() => {
  const { t } = useAppTranslation('contracts');
  const dispatch = useAppDispatch();
  const { userId } = useParams();

  const { data } = useGetContractsByUserIdQuery({ userId, isActive: false }, { skip: !userId });

  const userName = data?.length ? `${data[0].user.firstName} ${data[0].user.lastName}` : '';

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const columns = useMemo<ColumnsType<Contract>>(
    () => [
      {
        title: t('Warehouse'),
        key: 'warehouse',
        render: (_, record) => (
          <Link className="text-accent underline font-normal" to={`${AppRoutes.WAREHOUSES}/${record.warehouse.warehouseId}`}>
            {record.warehouse.name}
          </Link>
        ),
      },
      {
        title: t('Contract number'),
        key: 'contractNumber',
        render: (_, record) => (
          <div
            className="text-accent underline font-normal cursor-pointer"
            onClick={() => {
              openContractDetails(record.contractId);
            }}
          >
            #{record.contractNumber}
          </div>
        ),
      },
      {
        title: t('Area'),
        key: 'area',
        render: (_, record) => {
          const boxItem = record.contractItems?.find((item) => Boolean(item.box))?.box || null;

          return (
            <>
              <div>{boxItem?.square} m²</div>
              <div className="font-normal text-sm">{boxItem?.name}</div>
            </>
          );
        },
      },
      {
        title: (
          <>
            <div>{t('Date of creation')}</div>
            <div>{t('Arrival Date')}</div>
          </>
        ),
        key: 'dateOfCreationArrivalDate',
        render: (_, record) => (
          <>
            <div className="font-medium">{getClientDateFormat(record.createdAt)}</div>
            <div className="text-sm font-normal">{getClientDateFormat(record.startDate)}</div>
          </>
        ),
      },
      {
        title: t('Planned Departure Date'),
        key: 'plannedDepartureDate',
        render: (_, record) => <div className="font-normal">{getClientDateFormat(record.noticeLeaveDate)}</div>,
      },
      { title: t('Closing Date'), key: 'closingDate', render: (_, record) => getClientDateFormat(record.leaveDate) },
      { title: t('Paid Days'), key: 'paidDays' }, // TODO получить последнюю оплату по договору, взять у неё конечную дату (date to). От неё отнять сегодняшнюю дату
      {
        title: (
          <>
            <div>{t('Accrued for payment')}</div>
            <div>{t('Paid')}</div>
          </>
        ),
        key: 'accruedForPaymentPaid', // TODO нет на бэке
      },
    ],
    [openContractDetails, t],
  );

  return (
    <div className="space-y-4">
      <Trans
        t={t}
        i18nKey={`<0>User Contracts</0> <1>${userName}</1>`}
        components={[<span key="0" className="font-semibold text-3xl" />, <span key="1" className="text-accent font-semibold text-3xl" />]}
      />
      <Table data={data} columns={columns} rowKey="contractId" title={t('Rental history')} />
    </div>
  );
});
