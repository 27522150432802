import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { FC, memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { UserRoles } from 'entities/User';
import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { UpdateUser } from 'features/UpdateUser';
import { InputNumber } from 'shared/ui/InputNumber';
import { PHONE_NUMBER_REGEX, TWO_DECIMALS_FLOAT_REGEX } from 'shared/utils/regex';
import { NewPasswordModal } from './NewPasswordModal';

interface AccountDetailsFormProps {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  balance: Nullable<number>;
  role: UserRoles | undefined;
}

export const AccountDetailsForm: FC<AccountDetailsFormProps> = memo((props) => {
  const { userId, firstName, lastName, role, email, phone, dateOfBirth, balance } = props;

  const { t } = useAppTranslation(['profile', 'common']);

  const [newPasswordModalOpened, setNewPasswordModalOpened] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      balance,
      dateOfBirth: dayjs(dateOfBirth),
    });
  }, [balance, dateOfBirth, email, firstName, form, lastName, phone]);

  const isAdmin = role === UserRoles.SUPERUSER || role === UserRoles.MANAGER;

  const openNewPasswordModal = useCallback(() => {
    setNewPasswordModalOpened(true);
  }, []);

  const closeNewPasswordModal = useCallback(() => {
    setNewPasswordModalOpened(false);
  }, []);

  const submitButtons = (): ReactElement => {
    return isAdmin ? (
      <UpdateUser
        userId={userId}
        formInstance={form}
        action={
          <Button containerClassName="w-full" size="large">
            {t('Change account details')}
          </Button>
        }
      />
    ) : (
      <Button containerClassName="w-full" size="large" onClick={openNewPasswordModal}>
        {t('Reset Password')}
      </Button>
    );
  };

  return (
    <div className="mt-10">
      <div className="mb-7 font-semibold text-2xl">{t('Account details')}</div>
      <Form className="space-y-2" name="accountDetailsForms" form={form} layout="vertical" disabled={!isAdmin}>
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="firstName"
            label={t('Name')}
            rules={[{ required: true, message: t('Please enter your first name!') }]}
          >
            <Input bordered placeholder={t('Enter first name')} />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="lastName"
            label={t('Last Name')}
            rules={[{ required: true, message: t('Please enter your last name!') }]}
          >
            <Input bordered placeholder={t('Enter last name')} />
          </Form.Item>
        </div>
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="email"
            label={t('Email')}
            rules={[
              { required: true, message: t('Please enter your email!') },
              { type: 'email', message: t('Please enter correct email!') },
            ]}
          >
            <Input bordered placeholder={t('Enter email')} />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="phone"
            label={t('Phone')}
            rules={[
              { required: true, message: t('Please enter your phone!') },
              { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number. Example: +123456789012345') },
            ]}
          >
            <Input bordered placeholder={t('Enter phone')} />
          </Form.Item>
        </div>
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="dateOfBirth"
            label={t('Date of Birth')}
            rules={[{ required: true, message: t('Please enter your date of birth!') }]}
          >
            <DatePicker bordered className="h-[52px] w-full" format={CLIENT_DATE_FORMAT} />
          </Form.Item>
          {submitButtons()}
        </div>
      </Form>
      <NewPasswordModal userId={userId} isOpened={newPasswordModalOpened} onClose={closeNewPasswordModal} />
    </div>
  );
});
