import { Divider, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, memo, useCallback, useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { GoogleAuthButton } from 'features/GoogleAuthButton';
import { getGlobalSettings } from 'app/appState';
import { BookingDocumentsNames } from 'app/appState/model/types';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData, useCheckUserDataComplete, useAuthorizedState, UserAccountType } from 'entities/User';
import { WarehouseForBooking } from 'entities/Warehouse';
import { Box } from 'entities/Box';
import { ContractInfo, contractOptionsActions, useContractOptions } from 'entities/Contract';
import { logInModalActions } from 'features/LogInModal';
import { SuccessScreen } from 'shared/ui/SuccessScreen';
import { DatePicker } from 'shared/ui/DatePicker';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { bookingPageActions, getUserDetailsInfo } from 'pages/BookingPage';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { UserDetailsInfo } from '../model/types';
import { useProceedToPayment } from '../utils/hooks/useProceedToPayment';
import { useReserveBox } from '../utils/hooks/useReserveBox';
import { AccountTypeSelect } from './AccountTypeSelect';
import { Checkbox } from 'shared/ui/Checkbox';
import { setInitialUserDetailsFields } from '../utils/helpers/setInitialUserDetailsFields';
import { ProceedToPaymentButton } from './ProceedToPaymentButton';
import { ReserveBoxButton } from './ReserveBoxButton';
import { GratitudeModalType, useOpenGratitudeModal } from 'features/GratitudeModal';
import { AppRoutes } from 'app/config/routerConfig/types';
import { PhoneInput } from 'shared/ui/PhoneInput';

interface UserDetailsFormProps {
  warehouseInfo: Nullable<WarehouseForBooking>;
  boxInfo: Nullable<Box>;
  contractInfo: ContractInfo;
  onCompleteStep: () => void;
  onRentOptionsChange: () => void;
}

export const UserDetailsForm: FC<UserDetailsFormProps> = memo((props) => {
  const { warehouseInfo, boxInfo, contractInfo, onCompleteStep, onRentOptionsChange } = props;

  const { t } = useAppTranslation(['booking', 'common']);
  const globalSettings = useAppSelector(getGlobalSettings);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const privacyPolicyLink = useMemo(
    () => globalSettings?.bookingDocuments.find(({ docName }) => docName === BookingDocumentsNames.PRIVACY_POLICY)?.link || '#',
    [globalSettings],
  );

  const termsAndConditionsLink = useMemo(
    () => globalSettings?.bookingDocuments.find(({ docName }) => docName === BookingDocumentsNames.TERMS_AND_CONDITIONS)?.link || '#',
    [globalSettings],
  );

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  useLayoutEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  const [termsAgreement, setTermsAgreement] = useState(false);
  const [submitMode, setSubmitMode] = useState<Nullable<'reservation' | 'payment'>>(null);
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const loggedUserData = useAppSelector(getLoggedUserData);
  const userDetailsInfo = useAppSelector(getUserDetailsInfo);
  const isUserDataCompleted = useCheckUserDataComplete();
  const isAuthorized = useAuthorizedState();

  const { openGratitudeModal } = useOpenGratitudeModal();

  const userAccountType = contractInfo.accountType;

  useContractOptions({
    userAccountType,
    user: loggedUserData,
    selectedWarehouse: warehouseInfo,
    box: boxInfo,
    onRentOptionsChange,
  });

  const disableFutureDatesUnder18 = useCallback((currentDate: Dayjs): boolean => {
    const today = dayjs().startOf('day');
    const eighteenYearsAgo = today.subtract(18, 'year').startOf('day');

    return currentDate.isAfter(eighteenYearsAgo);
  }, []);

  const datePickerValueUnder18 = dayjs(dayjs().subtract(18, 'years'));

  const [form] = Form.useForm();

  useEffect(() => {
    setInitialUserDetailsFields(form, loggedUserData, dispatch, contractInfo);
  }, [contractInfo, dispatch, form, loggedUserData]);

  const toggleTermsAgreement = useCallback((): void => {
    setTermsAgreement((prevState) => !prevState);
  }, []);

  const openLogInModal = useCallback((): void => {
    dispatch(logInModalActions.setOpened(true));
  }, [dispatch]);

  const onSuccessThirdPartyAuth = useCallback((): void => {
    setInitialUserDetailsFields(form, loggedUserData, dispatch);
  }, [dispatch, form, loggedUserData]);

  const onFailThirdPartyAuth = useCallback(
    (error: string): void => {
      console.log(error);
      showNotification('error', t('Error'), t('Error when authorization via Google'));
    },
    [t],
  );

  const handleFormChange = (): void => {
    const formValues: Partial<UserDetailsInfo> = form.getFieldsValue();

    dispatch(bookingPageActions.setUserDetails(formValues));
  };

  const onChangeAccountType = useCallback(
    (accountType: UserAccountType): void => {
      dispatch(contractOptionsActions.setContractOptions({ accountType }));
    },
    [dispatch],
  );

  const setReservationMode = useCallback((): void => {
    setSubmitMode('reservation');
  }, []);

  const setProceedToPaymentMode = useCallback((): void => {
    setSubmitMode('payment');
  }, []);

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setSubmitMode(null);
    setOpenedConfirm(false);
  }, []);

  const handleOpenGratitudeModal = useCallback((): void => {
    openGratitudeModal({
      description: t(
        'Our company values each and every customer. If you have any questions or feedback, please don`t hesitate to reach out!',
      ),
      type: GratitudeModalType.RESERVATION,
      buttonId: 'reservation id', // TODO ADD ID FROM MARCUS !!!
    });
  }, [openGratitudeModal, t]);

  const onReservationSuccess = useCallback(() => {
    navigate(AppRoutes.MY_BOXES);

    handleOpenGratitudeModal();
  }, [handleOpenGratitudeModal, navigate]);

  const { isReservationLoading, executeReservation } = useReserveBox({
    boxInfo,
    contractInfo,
    warehouseInfo,
    closeConfirmModal,
    formData: userDetailsInfo,
    onSuccess: onReservationSuccess,
  });

  const { isProceedToPaymentLoading, executeProceedToPayment } = useProceedToPayment({
    formData: userDetailsInfo,
    warehouseInfo,
    boxInfo,
    contractInfo,
    closeConfirmModal,
    onCompleteStep,
  });

  if (isAuthorized && isUserDataCompleted) {
    return (
      <div className="p-12">
        <SuccessScreen
          title={t('Congratulations!')}
          description={t('You have been already registered')}
          actions={<Button onClick={onCompleteStep}>{t('Go to payment step')}</Button>}
        />
      </div>
    );
  }

  return (
    <div className="bg-secondaryLight rounded-lg p-6">
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t(submitMode === 'reservation' ? 'Reserve box' : 'Your details')}
        description={t(
          submitMode === 'reservation' ? 'Are you sure you want to reserve this box?' : 'Are you sure you want to save your details?',
        )}
        isLoading={submitMode === 'reservation' ? isReservationLoading : isProceedToPaymentLoading}
        onOk={submitMode === 'reservation' ? executeReservation : executeProceedToPayment}
        onCancel={closeConfirmModal}
      />
      <Form className="space-y-6" name="basic" form={form} autoComplete="off" onFinish={openConfirmModal} onValuesChange={handleFormChange}>
        <div ref={scrollRef} className="font-semibold text-2xl desktop:text-3xl">
          {t(!isAuthorized ? 'Login or register to book' : 'Fill in empty fields to continue')}
        </div>
        {!isAuthorized && (
          <>
            <div className="flex flex-col desktop:flex-row desktop:justify-center">
              <GoogleAuthButton className="desktop:w-1/2" onSuccessAuth={onSuccessThirdPartyAuth} onFailAuth={onFailThirdPartyAuth}>
                {t('Register with Google')}
              </GoogleAuthButton>
              {/* <FacebookAuthButton className="w-full" onSuccessAuth={onSuccessThirdPartyAuth} onFailAuth={onFailThirdPartyAuth}> */}
              {/*   {t('Register with Facebook')} */}
              {/* </FacebookAuthButton> */}
            </div>
            <Divider className="[&>*]:font-normal">{t('or')}</Divider>
          </>
        )}
        <div>
          <div className="flex flex-col mb-3 desktop:flex-row desktop:justify-between">
            <div className="text-lg font-semibold">{t('Customer information')}</div>
            {!isAuthorized && (
              <div className="flex items-center text-sm text-primaryLight font-normal">
                {t('Already have an account?')}
                <Button className="ml-1" theme="clear" fontSize="small" onClick={openLogInModal}>
                  {t('Log in')}
                </Button>
              </div>
            )}
          </div>
          {isAuthorized && !isUserDataCompleted && <AccountTypeSelect onChange={onChangeAccountType} />}
          <div className="flex flex-col desktop:flex-row desktop:space-x-3">
            <Form.Item
              className="w-full"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t('Please enter your first name!'),
                },
                {
                  min: 2,
                  max: 30,
                  message: t('First name must have 2 - 30 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('First name')} requiredMark />
            </Form.Item>
            <Form.Item
              className="w-full"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t('Please enter your last name!'),
                },
                {
                  min: 2,
                  max: 30,
                  message: t('Last name must have 2 - 30 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('Last name')} requiredMark />
            </Form.Item>
          </div>
          <div className="flex flex-col desktop:flex-row desktop:space-x-3">
            <Form.Item
              className="w-full"
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('Please enter your phone!'),
                },
                {
                  pattern: PHONE_NUMBER_REGEX,
                  message: t('Please provide a valid phone number. Example: +123456789012345'),
                },
              ]}
            >
              <PhoneInput placeholder={t('Phone')} size="large" requiredMark />
            </Form.Item>

            <Form.Item
              className="w-full"
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: t('Please enter your date of birth!'),
                },
              ]}
            >
              <DatePicker
                className="h-[52px] w-full"
                format={CLIENT_DATE_FORMAT}
                placeholder={t('Date of birth')}
                inputReadOnly
                bordered={false}
                disabledDate={disableFutureDatesUnder18}
                defaultPickerValue={datePickerValueUnder18}
                requiredMark
              />
            </Form.Item>
          </div>
          {/* <Form.Item name="identificationNumber">
            <Input size="large" placeholder={t('Identification number')} />
          </Form.Item> */}
          {contractInfo.accountType === 'business' && (
            <Form.Item
              name="company"
              rules={[
                {
                  required: true,
                  message: t('Please enter your company name!'),
                },
                {
                  min: 2,
                  max: 100,
                  message: t('Company name must have 2 - 100 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('Company')} requiredMark />
            </Form.Item>
          )}
          {!isAuthorized && (
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('Please enter your email!') },
                { type: 'email', message: t('Please enter correct email!') },
              ]}
            >
              <Input size="large" placeholder={t('Email')} requiredMark />
            </Form.Item>
          )}
          {!isAuthorized && (
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('Please enter your password!') },
                {
                  min: 8,
                  max: 20,
                  message: t('Password must have 8 - 20 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('Password')} requiredMark />
            </Form.Item>
          )}
        </div>
        <div>
          <div className="text-lg font-semibold mb-3">{t('Home address')}</div>
          <div className="flex flex-col desktop:flex-row desktop:space-x-3">
            <Form.Item
              className="flex-1 w-full"
              name="street"
              rules={[
                {
                  required: true,
                  message: t('Please enter your street!'),
                },
                {
                  min: 2,
                  message: t('Street must have at least 2 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('Street')} requiredMark />
            </Form.Item>
            <div className="flex gap-3 flex-1">
              <Form.Item
                className="w-full"
                name="house"
                rules={[
                  {
                    required: true,
                    message: t('Please enter your house!'),
                  },
                  {
                    min: 1,
                    max: 30,
                    message: t('House must have 1 - 30 characters!'),
                  },
                ]}
              >
                <Input size="large" placeholder={t('House')} requiredMark />
              </Form.Item>
              <Form.Item
                className="w-full"
                name="apartment"
                rules={[
                  {
                    max: 30,
                    message: t('Apartment must have no more than 30 characters!'),
                  },
                ]}
              >
                <Input size="large" placeholder={t('Apartment')} />
              </Form.Item>
            </div>
          </div>
          <div className="flex space-x-3">
            <Form.Item
              className="w-full"
              name="zip"
              rules={[
                {
                  required: true,
                  message: t('Please enter your zip code!'),
                },
                {
                  min: 2,
                  max: 30,
                  message: t('Zip code must have 2 - 30 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('Zip code')} requiredMark />
            </Form.Item>
            <Form.Item
              className="w-full"
              name="city"
              rules={[
                {
                  required: true,
                  message: t('Please enter your city!'),
                },
                {
                  min: 2,
                  max: 30,
                  message: t('City must have 2 - 30 characters!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('City')} requiredMark />
            </Form.Item>
          </div>
          <Form.Item
            className="w-full"
            name="country"
            rules={[
              {
                required: true,
                message: t('Please enter your country!'),
              },
              {
                min: 2,
                max: 30,
                message: t('Country must have 2 - 30 characters!'),
              },
            ]}
          >
            <Input size="large" placeholder={t('Country')} requiredMark />
          </Form.Item>

          <Checkbox
            className="[&>*]:text-sm [&>*]:font-normal [&>*]:text-primaryLight"
            name="agreement"
            requiredMark
            checked={termsAgreement}
            onChange={toggleTermsAgreement}
          >
            <Trans
              t={t}
              i18nKey="I have read and agree with the <0>Privacy Policy</0> and <1>Terms & conditions</1>"
              components={[
                <Link key="0" className="text-accent text-sm" to={privacyPolicyLink} target="_blank" />,
                <Link key="1" className="text-accent text-sm" to={termsAndConditionsLink} target="_blank" />,
              ]}
            />
          </Checkbox>
        </div>
        <div className="flex flex-col flex-col-reverse gap-3 desktop:flex-row desktop:space-x-3 desktop:gap-0">
          <ReserveBoxButton className="w-full" isDisabled={!termsAgreement} formInstance={form} setReservationMode={setReservationMode} />
          <ProceedToPaymentButton isDisabled={!termsAgreement} formInstance={form} setProceedToPaymentMode={setProceedToPaymentMode} />
        </div>
      </Form>
    </div>
  );
});
