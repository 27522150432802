import classNames from 'classnames';
import React, { FC, memo, useCallback, useLayoutEffect } from 'react';
import { Trans } from 'react-i18next';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { GeolocationCoords } from 'app/types/common';
import { getWorkingScheduleString, WarehouseForBooking } from 'entities/Warehouse';
import { ReactComponent as CameraIcon } from 'shared/assets/icons/CameraIcon.svg';
import { ReactComponent as ClockIcon } from 'shared/assets/icons/ClockIcon.svg';
import { ReactComponent as EmailIcon } from 'shared/assets/icons/EmailIcon.svg';
import { ReactComponent as PaymentCardIcon } from 'shared/assets/icons/PaymentCardIcon.svg';
import { ReactComponent as PhoneIcon } from 'shared/assets/icons/PhoneIcon.svg';
import { ReactComponent as TrolleyIcon } from 'shared/assets/icons/TrolleyIcon.svg';
import { ReactComponent as SmartphoneIcon } from 'shared/assets/icons/SmartphoneIcon.svg';
import WarehouseDefaultImage from 'shared/assets/images/WarehouseDefaultImage.png';
import { Button } from 'shared/ui/Button';
import { CopyTooltip } from 'shared/ui/CopyTooltip';
import { Paragraph } from 'shared/ui/Paragraph';
import { Tag } from 'shared/ui/Tag';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { warehouseMapActions } from '../../model/slice/warehouseMapSlice';
import { SizeGroupsTags } from 'entities/SizeCode';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';

interface ExpandedWarehouseCardProps {
  warehouse: WarehouseForBooking;
  isOutlined: boolean;
  onSelectCard: (coords: GeolocationCoords) => void;
  onViewBoxes: () => void;
  clipCard: () => void;
}

export const ExpandedWarehouseCard: FC<ExpandedWarehouseCardProps> = memo((props) => {
  const {
    warehouse: {
      warehouseId,
      name,
      imageUrl,
      address,
      email,
      zip,
      phone,
      description,
      workingSchedule,
      sizeCodes,
      minPrice,
      longitude,
      latitude,
      availableBoxesAmount,
      totalBoxesAmount,
      launchDate,
      isComingSoon,
    },
    isOutlined,
    onViewBoxes,
    onSelectCard,
    clipCard,
  } = props;

  const { t } = useAppTranslation('booking');

  const dispatch = useAppDispatch();

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  useLayoutEffect(() => {
    isOutlined && scrollToElement();
  }, [isOutlined, scrollToElement]);

  const currencySymbol = useGetCurrencySymbol();

  const fullAddress = `${address} ${zip || ''}`;
  const warehouseImage = imageUrl || WarehouseDefaultImage;

  const workingScheduleString = getWorkingScheduleString(workingSchedule);

  const selectCard = (): void => {
    onSelectCard({ lat: latitude, lng: longitude });
  };

  const handleViewBoxes = useCallback((): void => {
    dispatch(warehouseMapActions.setSelectedCard(warehouseId));
    onViewBoxes();
  }, [dispatch, onViewBoxes, warehouseId]);

  const onCloseCard = useCallback((): void => {
    dispatch(warehouseMapActions.setSelectedCard(null));
    clipCard();
  }, [clipCard, dispatch]);

  return (
    <div
      ref={scrollRef}
      className={classNames('rounded-lg bg-white ease-linear duration-200 cursor-pointer', {
        'shadow-borderLike shadow-accent': isOutlined,
      })}
      onClick={selectCard}
    >
      {isComingSoon && launchDate && (
        <div className="text-center py-2 bg-accent text-white rounded">
          {t('Openning')} {getClientDateFormat(launchDate)}!
        </div>
      )}

      <div className="p-4">
        <div className="font-semibold text-lg desktop:text-2xl">{name}</div>
        <CopyTooltip className="cursor-pointer text-primaryLight text-sm font-normal">{fullAddress}</CopyTooltip>
        <img className="rounded-lg h-[139px] w-full object-cover my-4" src={warehouseImage} alt="Warehouse photo" />
        {Boolean(minPrice) && (
          <div className="shrink-0 text-2xl font-semibold mb-3">
            <Trans
              t={t}
              i18nKey="From <0>{{minPrice}} {{currencySymbol}}/month</0>"
              components={[<span key="0" className="text-accent text-2xl font-semibold" />]}
              values={{ minPrice, currencySymbol }}
            />
          </div>
        )}
        <div className="space-y-2.5">
          <div className="flex items-center space-x-2.5">
            <EmailIcon className="shrink-0 [&>path]:stroke-accent" />
            <CopyTooltip className="cursor-pointer text-primaryLight text-sm font-normal">{email}</CopyTooltip>
          </div>
          <div className="flex items-center space-x-2.5">
            <PhoneIcon className="shrink-0 [&>path]:stroke-accent" />
            <CopyTooltip className="cursor-pointer text-primaryLight text-sm font-normal">{phone}</CopyTooltip>
          </div>
          <div className="flex items-center text-primaryLight text-sm font-normal">
            <ClockIcon className="mr-2 shrink-0 [&>path]:stroke-accent [&>circle]:stroke-accent" />
            {workingScheduleString}
          </div>
        </div>
        <div className="text-success my-4">
          {t('{{availableBoxesAmount}} boxes free out of {{totalBoxesAmount}}', { availableBoxesAmount, totalBoxesAmount })}
        </div>
        <SizeGroupsTags sizeCodes={sizeCodes} />
        <div className="flex flex-wrap gap-2 my-9">
          <Tag icon={<CameraIcon className="[&>path]:fill-accent" />}>{t('Security cameras monitoring')}</Tag>
          <Tag icon={<ClockIcon className="[&>*]:stroke-accent [&>*]:stroke-1" />}>{t('24 / 7 direct access')}</Tag>
          <Tag icon={<TrolleyIcon className="[&>path]:fill-accent" />}>{t('Free use of trolleys')}</Tag>
          <Tag icon={<SmartphoneIcon className="[&>path]:stroke-accent" />}>{t('Opened by app')}</Tag>
          <Tag icon={<PaymentCardIcon className="stroke-accent" />}>{t('Online payments')}</Tag>
        </div>
        <Paragraph className="text-base font-normal !mb-9 [&>*]:text-sm [&>*]:font-normal" rows={2}>
          {getLocalizedString(description)}
        </Paragraph>
        <div className="flex space-x-2.5">
          <Button containerClassName="flex-1" theme="secondary" onClick={onCloseCard}>
            {t('Close')}
          </Button>
          <Button containerClassName="flex-1" onClick={handleViewBoxes}>
            {t('View boxes')}
          </Button>
        </div>
      </div>
    </div>
  );
});
