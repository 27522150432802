import { Divider } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { getGlobalSettings, useGetCurrencySymbol } from 'app/appState';
import { ReactComponent as CancelIcon } from 'shared/assets/icons/CancelIcon.svg';
import { ReactComponent as ContractWhiteIcon } from 'shared/assets/icons/ContractWhiteIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getDepositAmount } from 'entities/Invoice';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { Reservation } from 'entities/Reservation';
import { reservationDetailDrawerActions } from '../model/slice/reservationDetailDrawerSlice';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { cancelReservationModalActions } from 'features/CancelReservationModal';
import { useGetOneVatRateQuery } from 'entities/VatRate';
import { UnitType, getVatRateAmount } from 'entities/Contract';
import { calculateDiscountValues } from 'entities/Discount';
import { checkExpirationDate } from 'shared/utils/helpers/checkExpirationDate';
import { takeUpReservationModalActions } from 'features/TakeUpReservationModal';

interface ReservationDetailsProps {
  reservation: Reservation;
}

export const ReservationDetails: FC<ReservationDetailsProps> = memo((props) => {
  const { reservation } = props;

  const {
    reservationNumber,
    user,
    invoiceFrequencyType,
    startRentDate,
    endRentDate,
    boxes,
    invoiceFrequency,
    cancellationDate,
    expirationDate,
    takenUpDate,
    warehouse,
  } = reservation;

  const dispatch = useAppDispatch();
  const { t } = useAppTranslation('contracts');

  const globalSettings = useAppSelector(getGlobalSettings);

  const boxVatRateParams = {
    accountType: user.accountType,
    unitType: UnitType.BOX,
  };

  const insuranceVatRateParams = {
    accountType: user.accountType,
    unitType: UnitType.INSURANCE,
  };

  const { data: boxVatRate } = useGetOneVatRateQuery({ warehouseId: warehouse.warehouseId, args: boxVatRateParams });
  const { data: insuranceVatRate } = useGetOneVatRateQuery({ warehouseId: warehouse.warehouseId, args: insuranceVatRateParams });

  const closeReservationDetails = useCallback((): void => {
    dispatch(reservationDetailDrawerActions.setOpenReservationDetails(null));
  }, [dispatch]);

  const openTakeUpReservationModal = useCallback((): void => {
    dispatch(takeUpReservationModalActions.openTakeUpReservationModal(reservation));
  }, [dispatch, reservation]);

  const currencySymbol = useGetCurrencySymbol();

  const box = boxes[0];
  const depositAmount = getDepositAmount(Boolean(globalSettings?.isDepositApplied), box);
  const rentalPrice = box?.monthRate || box?.dailyRate || box?.weekRate || 0;
  const boxVatRateAmount = getVatRateAmount(rentalPrice, boxVatRate?.ratePercent);

  const insuranceData = box?.insurance;
  const insuranceRate = insuranceData?.monthRate || insuranceData?.dailyRate || insuranceData?.weekRate || 0;
  const insuranceVatRatePercent = insuranceVatRate?.ratePercent || 0;
  const insuranceVatRateAmount = getVatRateAmount(insuranceRate, insuranceVatRatePercent);
  const insuranceAmountWithVat = Math.round((insuranceRate + insuranceVatRateAmount) * 100) / 100;

  const isExpiredReservation = checkExpirationDate(expirationDate);
  const isActiveReservation = !cancellationDate && !takenUpDate && !isExpiredReservation;

  const { totalDiscountPercent: firstServicePeriodDiscountPercent, totalDiscountAmount: firstServicePeriodDiscountAmount } =
    calculateDiscountValues(rentalPrice, box.discounts, invoiceFrequency, 1);

  const openCancelReservationModal = useCallback((): void => {
    dispatch(cancelReservationModalActions.openCancelReservationModal(reservation));
  }, [dispatch, reservation]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="font-semibold text-3xl">{t('Reservation details')}</div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={closeReservationDetails}>
          {t('Close')}
        </div>
      </div>
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="font-semibold text-lg">
          {t('Reservation')} #{reservationNumber}
        </div>
      </div>
      <BulletsTable
        heading={t('General Information')}
        theme="clear"
        rows={[
          { label: t('Client'), value: `${user.firstName} ${user.lastName}` },
          { label: t('Start Date'), value: getClientDateFormat(startRentDate) },
          { label: t('End Date'), value: getClientDateFormat(endRentDate) },
          { label: t('Renting period type'), value: t(invoiceFrequencyType) },
          { label: t('Box Number'), value: box?.name },
          { label: t('Area'), value: `${box?.square} m²` },
        ]}
      />
      <Divider className="border-primary" />
      <BulletsTable
        heading={t('Price details')}
        theme="clear"
        rows={[
          {
            label: (
              <Trans
                t={t}
                i18nKey="<0>Rental price</0> <1>for {{invoiceFrequency}} {{invoiceFrequencyType}}</1>"
                values={{ invoiceFrequency, invoiceFrequencyType }}
                components={[<div key="0" className="text-primaryLight font-normal" />, <div key="1" className="text-xs font-normal" />]}
              />
            ),
            value: rentalPrice ? `${rentalPrice} ${currencySymbol}` : null,
            emptyValueSymbol: `0 ${currencySymbol}`,
          },
          {
            label: `${t('VAT')}(${boxVatRate?.ratePercent}%)`,
            value: boxVatRateAmount ? `${boxVatRateAmount} ${currencySymbol}` : null,
            emptyValueSymbol: `0 ${currencySymbol}`,
          },
          {
            label: `${t('Discount')}(${firstServicePeriodDiscountPercent}%)`,
            value: firstServicePeriodDiscountAmount ? `${firstServicePeriodDiscountAmount} ${currencySymbol}` : null,
            emptyValueSymbol: '0%',
          },
          {
            label: t('Insurance Amount (inc. {{insuranceVatRatePercent}}% VAT)', {
              insuranceVatRatePercent,
            }),
            value: insuranceAmountWithVat ? `${insuranceAmountWithVat} ${currencySymbol}` : null,
            emptyValueSymbol: `0 ${currencySymbol}`,
          },
          {
            label: t('Insurance coverage amount'),
            value: insuranceData?.coverageAmount ? `${insuranceData.coverageAmount} ${currencySymbol}` : null,
            emptyValueSymbol: `0 ${currencySymbol}`,
          },
        ]}
      />
      <Divider className="border-primary" />
      {globalSettings?.isDepositApplied && (
        <BulletsTable
          heading={t('Other')}
          theme="clear"
          rows={[
            {
              label: t('Deposit'),
              value: depositAmount ? `${depositAmount} ${currencySymbol}` : null,
              emptyValueSymbol: `0 ${currencySymbol}`,
            },
          ]}
        />
      )}
      {isActiveReservation && (
        <div className="pt-12 space-y-4">
          <Button theme="outlined" icon={<CancelIcon />} iconPosition="prev" onClick={openCancelReservationModal}>
            {t('Cancel reservation')}
          </Button>

          <Button icon={<ContractWhiteIcon />} iconPosition="prev" onClick={openTakeUpReservationModal}>
            {t('Enter into contract')}
          </Button>
        </div>
      )}
    </>
  );
});
