export enum ModalMode {
  BOX_INFO = 'box_info',
  UPLOAD = 'upload',
  LOCK_INFO = 'lock_info',
}

export enum BoxAvailabilityFilter {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export enum BoxForOwnNeedsFilter {
  FOR_OWN_NEEDS = 'forOwnNeeds',
  COMMON = 'common',
}

export enum BoxNoteFilter {
  HAS_NOTES = 'hasNotes',
  WITHOUT_NOTES = 'withoutNotes',
}

export enum BoxesListFilterKeys {
  selectedWarehouseId = 'selectedWarehouseId',
  priceFrom = 'priceFrom',
  priceTo = 'priceTo',
  priceType = 'priceType',
  squareFrom = 'squareFrom',
  squareTo = 'squareTo',
  floorId = 'floorId',
  type = 'type',
  availability = 'availability',
  isForOwnNeeds = 'isForOwnNeeds',
  hasNotes = 'hasNotes',
}
