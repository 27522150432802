import { API_URL } from 'app/config/envVariables';
import { ExportEntity } from 'features/ExportModal/model/types';

export const getExportEnityUrl = (entity: ExportEntity): string => {
  switch (entity) {
    case ExportEntity.BOX:
      return `${API_URL}/boxes/export`;
    case ExportEntity.LEAD:
      return `${API_URL}/leads/export`;
    case ExportEntity.CONTRACT:
      return `${API_URL}/contracts/export`;
    case ExportEntity.INVOICE:
      return `${API_URL}/billing-documents/invoices/export`;
    case ExportEntity.USER:
      return `${API_URL}/users/export`;
  }
};
