import classNames from 'classnames';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Trans } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { ReactComponent as LocationIcon } from 'shared/assets/icons/LocationIcon.svg';
import { ReactComponent as ClockIcon } from 'shared/assets/icons/ClockIcon.svg';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/ArrowRight.svg';
import WarehouseDefaultImage from 'shared/assets/images/WarehouseDefaultImage.png';
import { getWorkingScheduleString, WarehouseForBooking } from 'entities/Warehouse';
import { GeolocationCoords } from 'app/types/common';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { CopyTooltip } from 'shared/ui/CopyTooltip';
import { getRoutedWarehouseId } from '../../model/selectors/getRoutedWarehouseId';
import { warehouseMapActions } from '../../model/slice/warehouseMapSlice';
import { SizeGroupsTags } from 'entities/SizeCode';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';

interface ClippedWarehouseCardProps {
  warehouse: WarehouseForBooking;
  isOutlined: boolean;
  onSelectCard: (coords: GeolocationCoords) => void;
  onGetRoute: (coords: GeolocationCoords, warehouseId: string) => void;
  onClearRoute: () => void;
  onViewBoxes: () => void;
}

export const ClippedWarehouseCard: FC<ClippedWarehouseCardProps> = memo((props) => {
  const {
    warehouse: {
      warehouseId,
      name,
      imageUrl,
      address,
      zip,
      workingSchedule,
      sizeCodes,
      totalBoxesAmount,
      availableBoxesAmount,
      minPrice,
      longitude,
      latitude,
      isComingSoon,
      launchDate,
    },
    isOutlined,
    onGetRoute,
    onClearRoute,
    onViewBoxes,
    onSelectCard,
  } = props;

  const { t } = useAppTranslation(['booking', 'common']);

  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const fullAddress = `${address} ${zip || ''}`;
  const warehouseImage = imageUrl || WarehouseDefaultImage;
  const workingScheduleString = getWorkingScheduleString(workingSchedule);

  const routedWarehouseId = useAppSelector(getRoutedWarehouseId);
  const isWarehouseRouted = routedWarehouseId === warehouseId;

  const selectCard = (): void => {
    onSelectCard({ lat: latitude, lng: longitude });
  };

  const handleGetRoute = useCallback((): void => {
    onGetRoute({ lat: latitude, lng: longitude }, warehouseId);
  }, [latitude, longitude, onGetRoute, warehouseId]);

  const handleViewBoxes = useCallback((): void => {
    dispatch(warehouseMapActions.setSelectedCard(warehouseId));
    onViewBoxes();
  }, [dispatch, onViewBoxes, warehouseId]);

  return (
    <div
      className={classNames('flex-col relative overflow-hidden rounded-lg bg-white ease-linear duration-200 cursor-pointer', {
        'shadow-borderLike shadow-accent': isOutlined,
      })}
      onClick={selectCard}
    >
      {/* {warehouseHasPromo && ( */}
      {/*   <div className="absolute bg-error w-[398px] h-[23px] rotate-[335deg] -left-[135px] top-[17px] text-center text-white"> */}
      {/*     {t('Has a Promo!')} */}
      {/*   </div> */}
      {/* )} */}

      {isComingSoon && launchDate && (
        <div className="text-center py-2 bg-accent text-white rounded">
          {t('Openning')} {getClientDateFormat(launchDate)}!
        </div>
      )}

      <div className="relative overflow-hidden rounded-lg bg-white p-4 ease-linear duration-200 cursor-pointer desktop:space-x-4 desktop:flex">
        <div className="hidden desktop:block desktop:basis-1/6">
          <img className="rounded-lg" src={warehouseImage} alt="Warehouse photo" />
        </div>
        <div className="flex flex-col items-start basis-5/6">
          <img
            className="rounded-lg max-h-[90px] w-full object-cover self-center desktop:hidden desktop:h-auto desktop:w-auto desktop:self-auto"
            src={warehouseImage}
            alt="Warehouse photo"
          />
          <div className="font-semibold mt-4 text-lg mb-2 desktop:text-2xl desktop:mt-auto">{name}</div>
          <div className="space-y-2 mb-4">
            <div className="flex items-center">
              <LocationIcon className="mr-2 stroke-accent" />
              <CopyTooltip className="text-accent underline cursor-pointer text-sm font-normal">{fullAddress}</CopyTooltip>
            </div>
            <div className="flex items-center text-primaryLight text-sm font-normal">
              <ClockIcon className="mr-2 [&>path]:stroke-accent [&>circle]:stroke-accent" width={16} height={16} />
              {workingScheduleString}
            </div>
          </div>
          <SizeGroupsTags sizeCodes={sizeCodes} />
          {Boolean(minPrice) && (
            <div className="shrink-0 text-xl font-semibold mb-3">
              <Trans
                t={t}
                i18nKey="From <0>{{minPrice}} {{currencySymbol}}/month</0>"
                components={[<span key="0" className="text-accent text-xl font-semibold" />]}
                values={{ minPrice, currencySymbol }}
              />
            </div>
          )}
          <div className="text-success mb-4">
            {t('{{availableBoxesAmount}} boxes free out of {{totalBoxesAmount}}', { availableBoxesAmount, totalBoxesAmount })}
          </div>
          <div className="flex justify-end items-center w-full">
            <div className="hidden space-x-2 desktop:flex">
              <Button theme={isWarehouseRouted ? 'danger' : 'secondary'} onClick={isWarehouseRouted ? onClearRoute : handleGetRoute}>
                {isWarehouseRouted ? t('Cancel', { ns: 'common' }) : t('Get a route')}
              </Button>
              <Button onClick={handleViewBoxes}>{t('View boxes')}</Button>
            </div>
          </div>
          <div className="flex space-x-2 w-full mt-2 desktop:hidden">
            <Button
              containerClassName="w-full"
              theme={isWarehouseRouted ? 'danger' : 'secondary'}
              onClick={isWarehouseRouted ? onClearRoute : handleGetRoute}
            >
              {isWarehouseRouted ? t('Cancel', { ns: 'common' }) : t('Get a route')}
            </Button>
            <Button containerClassName="w-full" icon={<ArrowRight />} onClick={handleViewBoxes}>
              {t('View units')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
